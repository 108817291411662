import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { BaseLineup } from '../../types';

interface LineupTableProps {
    lineups: BaseLineup[];
    sport: 'MLB' | 'NFL';
    isShowdown: boolean;
    site: 'DraftKings' | 'FanDuel';
}

const LineupTable: React.FC<LineupTableProps> = React.memo(({ lineups, sport, isShowdown, site }) => {
    // console.log('5. LineupTable rendering', lineups.length);
    const getPosition = (player: BaseLineup['players'][0]) => player.position.toLowerCase();

    const processMLBLineup = (lineup: BaseLineup): (BaseLineup['players'][0] | null)[] => {
        const processedPlayers: (BaseLineup['players'][0] | null)[] = new Array(10).fill(null);
        const positionCounts: { [key: string]: number } = {};
        const positionOrder = ['P', 'P', 'C', '1B', '2B', '3B', 'SS', 'OF', 'OF', 'OF'];

        lineup.players.forEach(player => {
            const positionIndex = positionOrder.indexOf(player.position);
            if (positionIndex !== -1) {
                const count = positionCounts[player.position] || 0;
                let availableIndex = -1;

                if (player.position === 'OF') {
                    availableIndex = positionOrder.findIndex((pos, index) => pos === 'OF' && !processedPlayers[index]);
                } else {
                    availableIndex = positionOrder.indexOf(player.position, positionIndex + count);
                }

                if (availableIndex !== -1 && !processedPlayers[availableIndex]) {
                    processedPlayers[availableIndex] = player;
                    positionCounts[player.position] = (positionCounts[player.position] || 0) + 1;
                }
            }
        });

        return processedPlayers;
    };

    const processNFLLineup = (lineup: BaseLineup): (BaseLineup['players'][0] | null)[] => {
        // console.log('Processing NFL lineup:', lineup);
        const processed: { [key: string]: BaseLineup['players'][0][] } = {
            QB: [], RB: [], WR: [], TE: [], DST: [], FLEX: []
        };

        lineup.players.forEach(player => {
            // console.log('Processing player:', player);
            const position = getPosition(player);
            if (!position) {
                console.error('Player missing position:', player);
                return;
            }
            if (['rb', 'wr', 'te'].includes(position)) {
                if (processed[position.toUpperCase()].length < (position === 'te' ? 1 : (position === 'rb' ? 2 : 3))) {
                    processed[position.toUpperCase()].push(player);
                } else {
                    processed.FLEX.push(player);
                }
            } else if (['qb', 'dst'].includes(position)) {
                processed[position.toUpperCase()].push(player);
            } else {
                console.warn('Unknown position:', position);
            }
        });

        // console.log('Processed lineup:', processed);

        return [
            processed.QB[0] || null,
            processed.RB[0] || null,
            processed.RB[1] || null,
            processed.WR[0] || null,
            processed.WR[1] || null,
            processed.WR[2] || null,
            processed.TE[0] || null,
            processed.FLEX[0] || null,
            processed.DST[0] || null
        ];
    };

    const processShowdownLineup = (lineup: BaseLineup): (BaseLineup['players'][0] | null)[] => {
        const isFanDuel = site === 'FanDuel';
        const processed: (BaseLineup['players'][0] | null)[] = new Array(isFanDuel ? 5 : 6).fill(null);
        const captain = lineup.players.find(p => getPosition(p) === (isFanDuel ? 'cpt' : 'cpt'));
        if (captain) processed[0] = captain;
        const flexPlayers = lineup.players.filter(p => getPosition(p) === 'flex');
        flexPlayers.forEach((player, index) => {
            if (index < (isFanDuel ? 4 : 5)) processed[index + 1] = player;
        });
        return processed;
    };

    const columns: GridColDef[] = useMemo(() => {
        const baseColumns: GridColDef[] = [
            { field: 'lineupId', headerName: 'ID', width: 50 },
            {
                field: 'totalProjection', headerName: 'Projection', width: 70, renderCell: (params: GridRenderCellParams) => {
                    return params.value != null ? params.value.toFixed(1) : '';
                }
            },
            {
                field: 'totalOwnership',
                headerName: 'Own%',
                width: 70,
                renderCell: (params: GridRenderCellParams) => {
                    return params.value != null ? params.value.toFixed(2) : '';
                }
            },
            { field: 'totalSalary', headerName: 'Salary', width: 70 },
        ];

        let positionColumns: GridColDef[];
        if (isShowdown) {
            const positions = site === 'FanDuel'
                ? ['CPT', 'FLEX', 'FLEX', 'FLEX', 'FLEX']
                : ['CPT', 'FLEX', 'FLEX', 'FLEX', 'FLEX', 'FLEX'];
            positionColumns = positions.map((pos, index) => ({
                field: `player_${index}`,
                headerName: pos,
                width: 100,
                renderCell: (params: GridRenderCellParams) => {
                    const player = params.value as BaseLineup['players'][0] | null;
                    return player ? `${player.playerId}` : '';
                },
            }));
        } else if (sport === 'NFL') {
            positionColumns = ['QB', 'RB', 'RB', 'WR', 'WR', 'WR', 'TE', 'FLEX', 'DST'].map((pos, index) => ({
                field: `player_${index}`,
                headerName: pos,
                width: 100,
                renderCell: (params: GridRenderCellParams) => {
                    const player = params.value as BaseLineup['players'][0] | null;
                    return player ? `${player.playerId}` : '';
                },
            }));
        } else {
            positionColumns = ['P', 'P', 'C', '1B', '2B', '3B', 'SS', 'OF', 'OF', 'OF'].map((pos, index) => ({
                field: `player_${index}`,
                headerName: pos,
                width: 100,
                renderCell: (params: GridRenderCellParams) => {
                    const player = params.value as BaseLineup['players'][0] | null;
                    return player ? `${player.playerId}` : '';
                },
            }));
        }

        return [...baseColumns, ...positionColumns];
    }, [sport, isShowdown]);

    const rows = useMemo(() => {
        // console.log('Creating rows from lineups:', lineups);
        if (!lineups || lineups.length === 0) return [];
        return lineups.map((lineup, index) => {
            let processedPlayers: (BaseLineup['players'][0] | null)[];
            if (isShowdown) {
                processedPlayers = processShowdownLineup(lineup);
            } else if (sport === 'NFL') {
                processedPlayers = processNFLLineup(lineup);
            } else {
                processedPlayers = processMLBLineup(lineup);
            }

            // console.log('Processed players:', processedPlayers);

            // Ensure there's always a unique id for each row
            const rowId = lineup.lineup_id || lineup.lineupId || `generated-id-${index}`;

            return {
                id: rowId,
                lineupId: rowId,
                totalProjection: lineup.totalProjection,
                totalSalary: lineup.totalSalary,
                totalOwnership: lineup.totalOwnership,
                ...processedPlayers.reduce((acc, player, index) => {
                    acc[`player_${index}`] = player;
                    return acc;
                }, {} as Record<string, BaseLineup['players'][0] | null>)
            };
        });
    }, [lineups, sport, isShowdown]);

    return (
        <Box sx={{ height: 500, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 50, page: 0 },
                    },
                }}
                pageSizeOptions={[50, 75, 100]}
                disableRowSelectionOnClick
            />
        </Box>
    );
});

export default LineupTable;