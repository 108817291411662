import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Paper, Button, Alert, Tooltip, Card, CardContent, Grid, Divider, Fade } from '@mui/material';
import { CloudUpload, Help, GetApp, LockOutlined } from '@mui/icons-material';
import { useAuth } from '../context/AuthContext';
import { useSlateContext } from '../context/SlateContext';
import { useSubscription } from '../context/SubscriptionContext';
import { collection, query, where, getDocs, orderBy, limit } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../firebase';
import Papa from 'papaparse';
import { SimulationResults } from '../types';
import Skeleton from '@mui/material/Skeleton';
import StackFrequencyTable from './StackFrequencyTable';
import LineupResultsTable from './LineupResultsTable';
import { useLineups } from '../context/LineupContext';
import { BaseLineup, SimulatedLineup } from '../types';
import { usePersistentState } from '../context/PersistentStateContext';
import { PersistentState, UploadedLineup } from '../context/PersistentStateContext';
import { Player } from '../types';

// interface Player {
//     id: string;
//     [key: string]: any;
// }

interface Lineup {
    lineupId: string;
    totalProjection: number;
    totalSalary: number;
    win_pct: number;
    top1p: number;
    top10p: number;
    cash_pct: number;
    roi: number;
    players: Player[];
}

// interface UploadedLineup {
//     lineupId: string;
//     players: {
//         playerId: string;
//         position: string;
//         salary: number;
//         projection: number;
//     }[];
//     totalProjection: number;
//     totalSalary: number;
// }

// interface ContestSimulatorProps {
//     results: SimulationResults | null;
//     updateResults: (results: SimulationResults) => void;
// }

const ContestSimulator: React.FC = () => {
    const { persistentState, updatePersistentState } = usePersistentState();
    const { isLoggedIn, user } = useAuth();
    const { sport, date, slateId, slateType, site } = useSlateContext();
    const { userSubscription } = useSubscription();
    // const { lineups, setLineups } = useLineups();
    const isShowdown = slateType === 'Showdown';
    const fileInputRef = useRef<HTMLInputElement>(null);

    const getMaxLineups = () => {
        switch (userSubscription?.subscriptionTier) {
            case 'rookie':
                return 5000;
            case 'mvp':
                return 20000;
            default:
                return 20;
        }
    };

    const {
        contestSize,
        simOption,
        lineupError,
        players,
        games,
        minProj,
        minOwn,
        maxOwn,
        minValue,
        selectedTeams,
        searchTerm,
        showResults,
        editedPlayers,
        selectedRows,
        lineupProgress,
        totalLineups,
        isOptimizerStarting,
        warnings,
        playerExposures,
        uploadedLineups,
        customProjections,
        usingCustomProjections,
        isLineupGenerationComplete,
        simulationResults,
        stackFrequencySummary,
        isLoading,
        lineupResults,
        simulatedLineups,
        lineups,
    } = persistentState;

    const isFreeTier = userSubscription?.subscriptionTier === 'free';


    useEffect(() => {
        if (isLoggedIn && user && slateId) {
            fetchPlayersAndLineups();
        } else if (!isLoggedIn) {
            updatePersistentState({ lineupError: "Please log in to use the Contest Simulator." });
        } else if (!slateId) {
            updatePersistentState({ lineupError: "Please select a slate before running the simulation." });
        }
    }, [isLoggedIn, user, slateId]);

    useEffect(() => {
        updatePersistentState({ totalLineups: lineups.length + uploadedLineups.length });
    }, [lineups, uploadedLineups]);

    useEffect(() => {
        if (lineupResults.length > 0 && stackFrequencySummary.length > 0) {
            updatePersistentState({ showResults: true });
        }
    }, [lineupResults, stackFrequencySummary]);

    const fetchPlayersAndLineups = async () => {
        try {
            updatePersistentState({ lineupError: null });
            const playersQuery = query(collection(db, 'data_players'), where('slate_identifier', '==', slateId), where('site', '==', site));
            const playerSnapshot = await getDocs(playersQuery);
            const playerList: Player[] = playerSnapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    id: doc.id,
                    Order: data.Order || 0,
                    Own: data.Own || 0,
                    Player: data.Player || '',
                    Pos: data.playerPosition || data.Pos || '',
                    Proj: data.Proj || 0,
                    Salary: data.Salary || 0,
                    StdDev: data.StdDev || 0,
                    Team: data.Team || data.teamAbbreviation || '',
                    Opp: data.Opp || data.opponent || '',
                    ID: data.ID || ''
                } as Player;
            });

            updatePersistentState({ players: playerList });
        } catch (error) {
            console.error('Error fetching data:', error);
            updatePersistentState({ lineupError: "An error occurred while fetching data. Please try again." });
        }
    };

    const convertToCSV = (data: any[]): string => {
        const playerIdMap = new Map(persistentState.players.map(player => [player.Player.trim(), player.ID]));

        const mlbPositions = ['P1', 'P2', 'C1', '1B1', '2B1', '3B1', 'SS1', 'OF1', 'OF2', 'OF3'];
        const nflPositions = ['QB1', 'RB1', 'RB2', 'WR1', 'WR2', 'WR3', 'TE1', 'FLEX', 'DST1'];
        const showdownPositions = ['CPT1', 'FLEX1', 'FLEX2', 'FLEX3', 'FLEX4', 'FLEX5'];

        let positions: string[];
        if (isShowdown) {
            positions = showdownPositions;
        } else if (sport === 'NFL') {
            positions = nflPositions;
        } else {
            positions = mlbPositions;
        }

        const columnOrder = ['lineup_id', 'UEscore', 'avgrnk', 'cash_pct', 'roi', 'win_pct', ...positions];

        const headers = columnOrder.join(',');
        const rows = data.map(row =>
            columnOrder.map(col => {
                if (positions.includes(col)) {
                    const playerName = (row[col] || '').trim();
                    const id = playerIdMap.get(playerName);
                    if (id) {
                        return `"${playerName} (${id})"`;
                    }
                    if (col === 'DST1') {
                        const dstMatch = Array.from(playerIdMap.entries()).find(([name, _]) =>
                            name.replace(/\s+/g, '').toLowerCase() === playerName.replace(/\s+/g, '').toLowerCase()
                        );
                        if (dstMatch) {
                            return `"${playerName} (${dstMatch[1]})"`;
                        }
                    }
                    return `"${playerName}"`;
                }
                const value = row[col];
                return typeof value === 'string' ? `"${value}"` : value;
            }).join(',')
        );
        return [headers, ...rows].join('\n');
    };

    const handleDownloadResults = () => {
        if (persistentState.lineupResults && persistentState.lineupResults.length > 0) {
            const csv = convertToCSV(persistentState.lineupResults);
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', 'contest_simulation_results.csv');
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    };

    const downloadCSV = (data: any[], filename: string) => {
        const csv = convertToCSV(data);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const convertToCSVlu = (lineupsToConvert: (BaseLineup | SimulatedLineup)[]): string => {
        const columnOrder = ['lineup_id', 'UEscore', 'avgrnk', 'cash_pct', 'roi', 'win_pct', 'P1', 'P2', 'C1', '1B1', '2B1', '3B1', 'SS1', 'OF1', 'OF2', 'OF3'];

        const headers = [
            'Lineup ID',
            'Total Projection',
            'Total Salary',
            'Win %',
            'Top 1%',
            'Top 10%',
            'Cash %',
            'ROI',
            ...columnOrder
        ];
        const csvRows = [headers.join(',')];

        lineupsToConvert.forEach(lineup => {
            const row = [
                lineup.lineupId,
                lineup.totalProjection.toFixed(2),
                lineup.totalSalary,
                'win_pct' in lineup ? (lineup.win_pct).toFixed(2) + '%' : '',
                'top1p' in lineup ? (lineup.top1p).toFixed(2) + '%' : '',
                'top10p' in lineup ? (lineup.top10p).toFixed(2) + '%' : '',
                'cash_pct' in lineup ? (lineup.cash_pct).toFixed(2) + '%' : '',
                'roi' in lineup ? (lineup.roi).toFixed(2) + '%' : '',
                ...columnOrder.map((pos, index) => {
                    const player = lineup.players.find(p => p.position === pos &&
                        lineup.players.filter(op => op.position === pos).indexOf(p) === columnOrder.slice(0, index + 1).filter(p => p === pos).length - 1);
                    return player ? `${player.playerId} ` : '';
                })
            ];
            csvRows.push(row.join(','));
        });

        return csvRows.join('\n');
    };


    const downloadCSVlu = () => {
        const csv = convertToCSVlu(persistentState.simulatedLineups.length > 0 ? persistentState.simulatedLineups : persistentState.lineups);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'contest_simulation_results.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };


    const handleRunSim = async () => {
        console.log("handleRunSim started", { contestSize, simOption, lineups });
        if (!user) {
            updatePersistentState({ error: "You must be logged in to run the simulation." });
            return;
        }

        if (isFreeTier && (contestSize === 'large' || simOption === 'corrDist')) {
            updatePersistentState({ error: "Please upgrade your subscription to access this feature." });
            return;
        }

        updatePersistentState({
            isLoading: true,
            showResults: false,
            error: null
        });

        if (lineups.length === 0 && uploadedLineups.length === 0) {
            updatePersistentState({
                error: "No lineups found. Please build or upload lineups before running the simulation.",
                isLoading: false
            });
            return;
        }

        try {
            const auth = getAuth();
            const user = auth.currentUser;

            if (!user) {
                throw new Error('User not authenticated');
            }

            const idToken = await user.getIdToken();

            // Use uploaded lineups if available, otherwise use built lineups
            const lineupsToSimulate = uploadedLineups.length > 0 ? uploadedLineups : lineups;

            const requestBody = {
                players: players,
                lineups: lineupsToSimulate.map(lineup => ({
                    ...lineup,
                    lineupId: lineup.lineupId
                })),
                slateId: slateId,
                contestSize: contestSize,
                simOption: simOption,
                userId: user.uid,
                site: site,
            };

            console.log('API Request Body:', JSON.stringify(requestBody, null, 2));

            let apiUrl = '';
            if (isShowdown) {
                apiUrl = 'https://ue-show-simv2-450591700064.us-central1.run.app/submit_job';
            } else if (sport === 'NFL') {
                apiUrl = 'https://ue-nfl-simv2-450591700064.us-central1.run.app/submit_job';
            } else {
                apiUrl = 'https://ue-mlb-simv2-450591700064.us-central1.run.app/submit_job';
            }

            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                },
                body: JSON.stringify(requestBody),
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error('Server error:', errorText);
                throw new Error(`Server error: ${response.status} ${errorText}`);
            }

            let responseText = await response.text();
            console.log('Raw response text:', responseText);

            // Preprocess the response text to handle NaN values for FanDuel Showdown
            if (site === 'FanDuel' && isShowdown) {
                responseText = responseText.replace(/"FLEX5":NaN/g, '"FLEX5":null');
            }

            let data;
            try {
                data = JSON.parse(responseText);
            } catch (parseError) {
                console.error('JSON Parse Error:', parseError);
                console.error('Problematic JSON string:', responseText);
                throw new Error('Failed to parse server response');
            }

            console.log('Raw data received from API:', data);

            const processedLineups = data.lineups.map((lineup: any, index: number): SimulatedLineup => {
                const processedLineup: SimulatedLineup = {
                    ...lineup,
                    lineupId: lineup.lineupId || `lineup-${index}`,
                    win_pct: lineup.win_pct || 0,
                    top1p: lineup.top1p || 0,
                    top10p: lineup.top10p || 0,
                    cash_pct: lineup.cash_pct || 0,
                    roi: lineup.roi || 0,
                };

                // Remove FLEX5 for FanDuel lineups
                if (site === 'FanDuel' && isShowdown && 'FLEX5' in processedLineup) {
                    const { FLEX5, ...restOfLineup } = processedLineup;
                    return restOfLineup;
                }

                return processedLineup;
            });

            console.log('Processed lineups:', processedLineups);

            updatePersistentState({
                simulatedLineups: processedLineups,
                lineupResults: processedLineups,
                stackFrequencySummary: data.stack_frequency_summary,
                showResults: true,
                isLoading: false,
            });

            console.log('Updated state:', {
                lineupResults: processedLineups,
                stackFrequencySummary: data.stack_frequency_summary,
                showResults: true,
            });
        } catch (error) {
            console.error('Error:', error);
            updatePersistentState({
                error: "An error occurred while running the simulation. Please try again.",
                isLoading: false
            });
        }
    };


    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            if (file.type !== 'text/csv') {
                updatePersistentState({ lineupError: "Please upload a CSV file." });
                alert("Please upload a csv file")
                return;
            }

            Papa.parse(file, {
                complete: (results) => {
                    console.log("Raw parsed data:", results.data);
                    const parsedData = results.data as any[];
                    if (parsedData.length === 0) {
                        updatePersistentState({ lineupError: "The CSV file is empty." });
                        alert("csv file had no lineups we were able to parse.")
                        return;
                    }

                    const headers = Object.keys(parsedData[0]);
                    console.log("CSV Headers:", headers);

                    let expectedColumns: string[];
                    if (isShowdown) {
                        expectedColumns = ['CPT', 'FLEX', 'FLEX_1', 'FLEX_2', 'FLEX_3', 'FLEX_4'];
                    } else if (sport === 'MLB') {
                        expectedColumns = ['P', 'P_1', 'C', '1B', '2B', '3B', 'SS', 'OF', 'OF_1', 'OF_2'];
                    } else {
                        expectedColumns = ['QB', 'RB', 'RB_1', 'WR', 'WR_1', 'WR_2', 'TE', 'FLEX', 'DST'];
                    }

                    if (!expectedColumns.every(col => headers.includes(col))) {
                        updatePersistentState({ lineupError: `CSV must contain these columns: ${expectedColumns.join(', ')}` });
                        alert("csv contains the wrong columns")
                        return;
                    }

                    let maxLineups: number;
                    switch (userSubscription?.subscriptionTier) {
                        case 'rookie':
                            maxLineups = 5000;
                            break;
                        case 'mvp':
                            maxLineups = 20000;
                            break;
                        default:
                            maxLineups = 20;
                    }

                    const parsedLineups: UploadedLineup[] = parsedData.slice(0, maxLineups).map((row: any, index: number) => {
                        console.log(`Processing row ${index}:`, row);
                        const players: { playerId: string; position: string; salary: number; projection: number }[] = [];

                        expectedColumns.forEach((col) => {
                            const playerId = row[col];
                            console.log(`Column ${col}, Player ID: ${playerId}`);
                            if (playerId && playerId.trim() !== '') {
                                let position = col.split('_')[0]; // Remove the _1, _2, etc. from the position
                                players.push({
                                    playerId: playerId,
                                    position: position,
                                    salary: 0,
                                    projection: 0
                                });
                            }
                        });

                        console.log(`Processed players for row ${index}:`, players);

                        return {
                            lineupId: (index + 1).toString(),
                            players: players,
                            totalProjection: parseFloat(row['Total Projection']) || 0,
                            totalSalary: parseFloat(row['Total Salary']) || 0
                        };
                    });

                    console.log("Final parsed lineups:", parsedLineups);

                    updatePersistentState({
                        uploadedLineups: parsedLineups,
                        lineupError: null,
                        totalLineups: parsedLineups.length
                    });
                    alert(`Uploaded ${parsedLineups.length} lineups successfully.`);
                },
                header: true,
                error: (error) => {
                    console.error('CSV parsing error:', error);
                    updatePersistentState({ lineupError: "Error parsing CSV file. Please check the file format." });
                }
            });
        }
    };

    const triggerFileUpload = () => {
        fileInputRef.current?.click();
    };

    if (!isLoggedIn) {
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'calc(100vh - 64px)', // Adjust this if your app bar height is different
                p: 3,
                textAlign: 'center'
            }}>
                <Typography variant="h4" gutterBottom>
                    Welcome to the DFS OS
                </Typography>
                <Typography variant="h5" color="error" sx={{ mb: 2 }}>
                    You need to be logged in to use this feature
                </Typography>
                <Typography variant="body1">
                    Please use the login button in the top right corner to access all features.
                </Typography>
                <Typography variant="body2" sx={{ mt: 2, fontStyle: 'italic' }}>
                    If you don't have an account yet, you can sign up for free!
                </Typography>
            </Box>
        );
    }

    // console.log('Rendering results:', {
    //     showResults,
    //     isLoading,
    //     lineupResultsLength: lineupResults.length,
    //     stackFrequencySummaryLength: stackFrequencySummary.length,
    // });
    return (

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, p: 0, pt: 8 }}>
            {persistentState.error && <Alert severity="error" sx={{ width: '100%' }}>{persistentState.error}</Alert>}
            <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                    <Card elevation={3} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flexGrow: 1 }}>
                            <Typography variant="h6" gutterBottom>Simulation Settings</Typography>
                            <FormControl component="fieldset" sx={{ width: '100%', mb: 2 }}>
                                <FormLabel component="legend">Contest Size</FormLabel>
                                <RadioGroup
                                    value={contestSize}
                                    onChange={(e) => updatePersistentState({ contestSize: e.target.value })}
                                >
                                    <FormControlLabel value="small" control={<Radio />} label="Small Field: < 1,000 entries" />
                                    <FormControlLabel value="medium" control={<Radio />} label="Medium Field: 5,000 entries" />
                                    <Tooltip title={isFreeTier ? "Upgrade to access large field contests" : ""}>
                                        <FormControlLabel
                                            value="large"
                                            control={
                                                <Radio
                                                    disabled={isFreeTier}
                                                    icon={isFreeTier ? <LockOutlined color="disabled" /> : undefined}
                                                    checkedIcon={isFreeTier ? <LockOutlined color="disabled" /> : undefined}
                                                />
                                            }
                                            label={
                                                <Typography color={isFreeTier ? 'text.disabled' : 'inherit'}>
                                                    Large Field
                                                </Typography>
                                            }
                                            sx={{ opacity: isFreeTier ? 1 : 1 }}
                                        />
                                    </Tooltip>
                                </RadioGroup>
                            </FormControl>
                            <FormControl component="fieldset" sx={{ width: '100%', mb: 0 }}>
                                <FormLabel component="legend">Simulation Options</FormLabel>
                                <RadioGroup
                                    value={simOption}
                                    onChange={(e) => updatePersistentState({ simOption: e.target.value })}
                                >
                                    <FormControlLabel value="randomness" control={<Radio />} label="Use Randomness" />
                                    <FormControlLabel value="playerDist" control={<Radio />} label="Use Player Distributions" />
                                    <Tooltip title={isFreeTier ? "Upgrade to access correlated distributions" : ""}>
                                        <FormControlLabel
                                            value="corrDist"
                                            control={
                                                <Radio
                                                    disabled={isFreeTier}
                                                    icon={isFreeTier ? <LockOutlined color="disabled" /> : undefined}
                                                    checkedIcon={isFreeTier ? <LockOutlined color="disabled" /> : undefined}
                                                />
                                            }
                                            label={
                                                <Typography color={isFreeTier ? 'text.disabled' : 'inherit'}>
                                                    Use Correlated Distributions
                                                </Typography>
                                            }
                                            sx={{ opacity: isFreeTier ? 1 : 1 }}
                                        />
                                    </Tooltip>
                                </RadioGroup>
                            </FormControl>
                        </CardContent>
                        <Divider />
                        <Box sx={{ p: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 1 }}>
                                {totalLineups > 0
                                    ? `${totalLineups} Lineups ready`
                                    : "No lineups available"}
                            </Typography>
                            <Box sx={{ display: 'flex', gap: 2 }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleRunSim}
                                    disabled={!contestSize || !simOption || (lineups.length === 0 && uploadedLineups.length === 0)}
                                >
                                    Run Sim
                                </Button>
                                <Box sx={{ position: 'relative' }}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        startIcon={<CloudUpload />}
                                        onClick={triggerFileUpload}
                                    >
                                        Upload
                                    </Button>
                                    <input
                                        type="file"
                                        accept=".csv"
                                        style={{ display: 'none' }}
                                        ref={fileInputRef}
                                        onChange={handleFileUpload}
                                    />
                                    <Tooltip title="Upload a CSV of lineups with columns for each position, exactly like you would upload to your DFS site.">
                                        <Help sx={{ position: 'absolute', right: -24, top: '50%', transform: 'translateY(-50%)', cursor: 'help' }} />
                                    </Tooltip>
                                </Box>
                            </Box>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card elevation={3} sx={{ height: '100%' }}>
                        <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="h6" gutterBottom>
                                {isShowdown ? "Simulated CPT Metrics" : "Simulated Stack Metrics"}
                            </Typography>
                            <Box sx={{ flexGrow: 1, overflowY: 'auto', position: 'relative' }}>
                                {isFreeTier ? (
                                    <Box sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                        zIndex: 1
                                    }}>
                                        <LockOutlined fontSize="large" color="disabled" />
                                        <Typography variant="body1" color="text.secondary" sx={{ mt: 2, textAlign: 'center' }}>
                                            Upgrade to access Simulated Stack Metrics
                                        </Typography>
                                    </Box>
                                ) : null}
                                {showResults ? (
                                    <StackFrequencyTable data={stackFrequencySummary} />
                                ) : (
                                    <Typography variant="body2" color="text.secondary">
                                        Simulated metrics will appear here after running the simulation.
                                    </Typography>
                                )}
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {isLoading && (
                <Card elevation={3}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>Simulation in progress...</Typography>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                            Depending on the settings you chose and the lineups you simulated this may take a minute or two.
                        </Typography>
                        <Skeleton variant="rectangular" width="100%" height={100} />
                        <Skeleton variant="rectangular" width="100%" height={400} sx={{ mt: 1 }} />
                    </CardContent>
                </Card>
            )}

            {showResults && !isLoading && (
                <Card elevation={3}>
                    <CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                            <Typography variant="h6">Lineup Results</Typography>
                            <Button
                                variant="outlined"
                                color="primary"
                                startIcon={<GetApp />}
                                onClick={handleDownloadResults}
                            >
                                Download Results CSV
                            </Button>
                        </Box>
                        <LineupResultsTable data={lineupResults} sport={sport as 'MLB' | 'NFL'} isShowdown={isShowdown} site={site as 'DraftKings' | 'FanDuel'} />
                    </CardContent>
                </Card>
            )}
        </Box>
    );
}

export default ContestSimulator;