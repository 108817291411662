import React, { useState, useMemo } from 'react';
import { Box, TextField, Select, MenuItem, Paper } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

interface PlayerExposure {
    id: string;
    playerId: string;
    name: string;
    position: string;
    team: string;  // Add this line
    exposure: number;
}

interface PlayerExposureTableProps {
    playerExposures: PlayerExposure[];
    sport: 'MLB' | 'NFL';
    isShowdown: boolean;
}

const PlayerExposureTable: React.FC<PlayerExposureTableProps> = React.memo(({ playerExposures, sport, isShowdown }) => {
    const [positionFilter, setPositionFilter] = useState<string>('All');
    const [searchTerm, setSearchTerm] = useState<string>('');

    const positions = useMemo(() => {
        if (isShowdown) {
            return ['CPT', 'FLEX'];
        } else if (sport === 'MLB') {
            return ['P', 'C', '1B', '2B', '3B', 'SS', 'OF'];
        } else {
            return ['QB', 'RB', 'WR', 'TE', 'DST'];
        }
    }, [sport, isShowdown]);

    const columns: GridColDef[] = [
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'position', headerName: 'Pos', width: 50 },
        { field: 'team', headerName: 'Team', width: 50 },
        {
            field: 'exposure',
            headerName: 'Exposure %',
            width: 110,
            renderCell: (params) => `${(params.value * 100).toFixed(2)}%`,
        },
    ];

    const filteredRows = useMemo(() => {
        return playerExposures.filter(player =>
            (positionFilter === 'All' || player.position === positionFilter) &&
            (player.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                player.team.toLowerCase().includes(searchTerm.toLowerCase()))
        );
    }, [playerExposures, positionFilter, searchTerm]);

    return (
        <Box>
            <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                <TextField
                    label="Search Player or Team"
                    variant="outlined"
                    size="small"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <Select
                    value={positionFilter}
                    onChange={(e) => setPositionFilter(e.target.value as string)}
                    size="small"
                >
                    <MenuItem value="All">All Positions</MenuItem>
                    {positions.map(pos => (
                        <MenuItem key={pos} value={pos}>{pos}</MenuItem>
                    ))}
                </Select>
            </Box>
            <Paper style={{ height: 400, width: '100%' }}>
                <DataGrid
                    rows={filteredRows}
                    columns={columns}
                    getRowId={(row) => row.id}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 50, page: 0 },
                        },
                        sorting: {
                            sortModel: [{ field: 'exposure', sort: 'desc' }],
                        },
                    }}
                    pageSizeOptions={[50, 75, 100]}
                    rowHeight={45}
                />
            </Paper>
        </Box>
    );
});

export default PlayerExposureTable;